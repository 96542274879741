.contentInnerContainer {
  padding: 0;
  flex: unset;
  flex-direction: column;
  
  .item {
    background-color: var(--background-color) !important;
    width: 100%;
  }
}
