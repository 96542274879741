.tenantName {
  font-weight: var(--font-weight--bold);
  margin-top: 1em;
}

.pkiButtonPrimary {
  background-color: var(--accent-color--red);
  border-color: var(--accent-color--red);
  color: var(--color-white);
}
