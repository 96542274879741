.filters {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  
  .pkiChip {
    background-color: var(--color-grey-600);
    border-color: var(--color-grey-600);
  }
  
  .clearFilterBtn {
    padding: 0;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

.contentInnerContainer {
  border: 1px solid var(--border-color);
  padding: 0;
  
  .list {
    fill: var(--primary-background);
    width: 100%;
  }
}

.pkiBadge {
  background-color: var(--background-color) !important;
  
  &.active {
    border-color: var(--color-green);
    color: var(--color-green);
  }
  
  &.inactive {
    border-color: var(--color-grey-500);
    color: var(--color-grey-500);
  }
}

.noDataContainer {
  align-items: center;
  background-color: var(--color-grey-50);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
  justify-content: center;
  margin: 24px;
}

.captionContainer {
  text-align: center;
  fill: var(--color-blue-300);
  
  .caption {
    color: var(--color-grey-700);
    font-weight: var(--font-weight--bold);
    margin: 48px auto 32px auto;
  }
}
