.security {
  flex: 1 1 auto;

  align-items: flex-start;
  display: flex;
  flex-direction: column;

  .title {
    color: var(--color-grey-900);
    font-family: var(--font-family--body);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .formContainer {
    margin-top: 16px;
    width: 100%;
  }

  .btnRow {
    align-items: center;
    display: flex;
    gap: 16px;
  }

  .mfaRequiredToggle {
  }
}
