.contentInnerContainer {
  padding: 24px;
  flex-wrap: wrap;
  
  .pki-data-grid.selectable {
    .MuiDataGrid-row {
      cursor: pointer;
    }
  }
}

.pkiToast {
  align-items: flex-start;
  background-color: var(--color-white);
  border: 1px solid var(--accent-color--greenn);
  border-radius: 4px;
  color: var(--accent-color--green);
  display: flex;
  font-family: var(--font-family--body);
  font-size: 13px;
  margin: 0;
  padding: 12px;
  
  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
}
