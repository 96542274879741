.datePickerMenu {
  display: none;
  outline: 0;
  padding: 16px 0;
  position: absolute;
  top: 34px;
  z-index: 100;

  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-color);

  &.open {
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.2));
  }

  &.rightAlign {
    right: 0;
  }
}

.selectFormGroup {
  margin: 0 16px 16px 16px;
}

.datePickerLegend {
  height: auto;
  margin: 0 16px;
}

.datePickerFooter {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin: 4px 16px 0 16px;
}
