.userProfile {
  flex: 1 1 auto;

  align-items: flex-start;
  display: flex;
  flex-direction: column;

  .title {
    color: var(--color-grey-900);
    font-family: var(--font-family--body);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .pictureContainer {
    margin-top: 8px;
    margin-bottom: 32px;

    .picture {
      margin-top: 10px;

      img {
        height: 146px !important;
        width: 146px !important;
        border-radius: 73px !important;
      }
    }
  }

  .formContainer {
    border-bottom: 1px solid var(--border-color);
    width: 100%;
  }

  .btnRow {
    align-items: center;
    display: flex;
    gap: 16px;
  }
}
