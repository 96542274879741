.auditDialog {
  width: 75vw;
  max-width: 75vw !important;
}

.entryLabel {
  flex: 0 0 auto !important;
  font-size: 14px;
}

.entryValue {
  flex: 1 1 auto !important;
  font-size: 14px;
  white-space: nowrap;
}

.detailContent {
  width: 100%;
}

.detailContainer {
  flex-wrap: nowrap !important;
}

.detailValue {
  flex: 1 1 auto !important;
  font-size: 14px;
}

.detailTitle {
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0;
}
