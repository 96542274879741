.subscriptionPickerContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  gap: 24px;
  justify-content: space-between;
  
  .subscriptionPicker {
    max-width: 700px;
    
    &.selected {
      border: none;
      padding: 0;
    }
    
    .subscriptionTag {
      background-color: var(--background-color--light-200);
      border-color: var(--background-color--light-200);
      color: var(--text-color);
      fill: var(--text-color);
    }
    
    .subscriptionOption,
    .selectedOption,
    .renderedValue {
      align-items: center;
      background-color: var(--color-blue-50);
      border-radius: 8px;
      display: flex;
      gap: 8px;
      justify-content: space-between;
      width: 100%;
      
      &.header, &.header:hover, &.header:focus {
        background-color: transparent;
        border-radius: 0;
        cursor: default;
        
        span {
          font-size: var(--font-size--12);
          font-weight: var(--font-weight--medium);
          text-transform: uppercase
        }
      }
      
      span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
      }
    }
    
    .selectedOption,
    .renderedValue {
      cursor: pointer;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
      padding: 4px 10px;
      max-width: 700px;
    }
  
    .selectedOptionDisabled,
    .renderedValueDisabled {
      background-color: var(--input-background-color--disabled);
      cursor: default;
      font-weight: 400;
    }
  
  }
}
