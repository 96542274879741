/* We use the :global pseudo class so we can reference the core toast class selectors  */
/* @see https://github.com/css-modules/css-modules */

.pkiNotification {
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));

  &:global(.Toastify__toast) {
    align-items: center;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: default;
    display: flex;
    font-family: var(--font-family--body);
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    min-height: 44px;
    padding: 12px 16px;
  }

  &:global(.Toastify__toast--info) {
    background-color: var(--accent-color--purple);
  }

  &:global(.Toastify__toast--success) {
    background-color: rgba(44, 133, 2, 1);
  }

  &:global(.Toastify__toast--warning) {
    background-color: var(--accent-color--orange);
  }

  &:global(.Toastify__toast--error) {
    background-color: var(--accent-color--red);
  }

  :global(.Toastify__toast-body) {
    align-items: flex-start;
    margin: 0;
    min-width: 0;
    padding: 0;
  }

  :global(.Toastify__toast-icon) {
    margin-right: 8px;
    height: 20px;
    width: 20px;

    svg {
      fill: var(--color-white);
    }
  }

  :global(button.Toastify__close-button) {
    color: var(--color-white);
  }

  :global(button.Toastify__close-button > svg) {
    fill: var(--color-white);
    margin-left: 8px;
  }
}

.messageContainer {
  align-items: flex-end;
  display: flex;
}

.notificationMessage {
  flex-grow: 1;
  max-height: 200px;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

.reportButton {
  background-color: transparent !important;
  border-color: #fff !important;
  border-radius: 3px !important;
  color: #fff !important;
  flex-shrink: 0;
  font-size: 11px !important;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    text-decoration: none;
  }
}
