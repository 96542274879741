.form-lede {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  
  svg {
    width: 22px;
  }
}

.tooltip {
  align-items: flex-start;
  color: var(--text-color);
  display: flex;
  font-size: 11px;
  line-height: 16px;
  margin-top: 8px;
  
  svg {
    flex: 0 0 auto;
    fill: var(--color-primary-blue);
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }
}
