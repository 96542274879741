.filters {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  
  .pkiChip {
    background-color: var(--color-grey-600);
    border-color: var(--color-grey-600);
  }
}

.clearFilterBtn {
  padding: 0 !important;
  
  &:hover {
    text-decoration: underline;
  }
}
