.pkiBadge {
  background-color: var(--background-color) !important;

  &.active {
    border-color: var(--color-green);
    color: var(--color-green);
  }

  &.inactive {
    border-color: var(--color-grey-500);
    color: var(--color-grey-500);
  }

  &.deploying {
    border-color: var(--color-primary-blue);
    color: var(--color-primary-bluer);
  }

  &.failed {
    border-color: var(--accent-color--red);
    color: var(--accent-color--red);
  }
}
