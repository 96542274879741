.routesContainer {
  align-items: stretch;
  background-color: var(--background-color--light-200);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  width: 240px;

  .routes {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .logoutBtn {
    text-align: center;
  }
}
