.datePickerContainer {
  overflow: visible;
  outline: none;
  position: relative;
  background-color: var(--background-color);
}

.valueText {
  align-items: center;
  background-color: var(--background-color);
  display: flex;
  justify-content: flex-start;
  padding: 4px 10px;
  width: 320px;
  
  .inputText {
    cursor: pointer;
    font-family: var(--font-family--body);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.005em;
    line-height: 24px;
  }
  
  svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
}
