.contentInnerContainer {
  padding: 0 !important;
}

.filters {
  display: flex;
  gap: 8px;
  margin-top: 12px;

  .pkiChip {
    background-color: var(--color-grey-600);
    border-color: var(--color-grey-600);
  }

  .clearFilterBtn {
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }
}

.noDataContainer {
  align-items: center;
  background-color: var(--color-grey-50);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
  justify-content: center;
  margin: 24px;

  .captionContainer {
    text-align: center;
    fill: var(--color-blue-300);

    .caption {
      color: var(--color-grey-700);
      font-weight: var(--font-weight--bold);
      margin: 48px auto 32px auto;
    }
  }
}
