.backdropRoot {
  inset: 0;
  position: fixed;
  z-index: 1300;
  visibility: hidden;
  transition: visibility 225ms ease-in-out;

  &.openRoot {
    visibility: visible;
  }

  .backdrop {
    align-items: center;
    background-color: var(--color-primary-blue);
    display: flex;
    inset: 0;
    justify-content: center;
    position: fixed;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    will-change: opacity;
    transition: opacity 225ms ease-in-out;

    &.openBackdrop {
      opacity: 0.7;
      pointer-events: auto;
    }
  }

  .container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    outline: 0;
    margin-top: 100vh;
    pointer-events: none;
    will-change: opacity;
    transition: margin-top 0.5s ease-in-out;

    &.openContainer {
      margin-top: 0;
      pointer-events: auto;
    }
  }
}

.dialog {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  margin: 40px auto 0 auto;
  overflow-y: hidden;
  padding: 24px;
  position: relative;
  width: 1000px;

  color: var(--text-color);
  font-family: var(--font-family--body);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  background-color: var(--background-color);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 11px 15px 3px rgba(0, 0, 0, 0.3);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .title {
      font-family: var(--font-family--headline);
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
    }

    .closeBtn {
      height: 27px;
      width: 40px;

      svg {
        margin-top: -6.5px;
        stroke: var(--text-color);
      }
    }
  }

  .contentContainer {
    display: flex;
    gap: 24px;
    height: 100%;

    .routes {
      align-items: stretch;
      background-color: var(--background-color--light-200);
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      padding: 16px;
      width: 240px;
    }
  }
}
