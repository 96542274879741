.empty {
  background-color: var(--background-color--light-200);
  
  .captionContainer {
    text-align: center;
    fill: var(--color-blue-300);
  
    .caption {
      color: var(--color-grey-700);
      font-weight: var(--font-weight--bold);
      margin-top: 24px;
    }
    
    svg {
      height: 166px;
      width: 166px;
    }
  }
}
